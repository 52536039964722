import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import panZoom from "vue-panzoom";
import VueClipboard from "vue-clipboard2";
Vue.use(panZoom);
import { library } from "@fortawesome/fontawesome-svg-core";
import IdleVue from "idle-vue";

import {
  faEnvelope,
  faUserSlash,
  faUnlockAlt,
  faEye,
  faEyeSlash,
  faCheck,
  faTimes,
  faChevronCircleDown,
  faCheckCircle,
  faCloudUploadAlt,
  faUsers,
  faIdCardAlt,
  faUsersCog,
  faCog,
  faLifeRing,
  faSignOutAlt,
  faUserCircle,
  faExclamationCircle,
  faFolderOpen,
  faFolderPlus,
  faFolder,
  faEllipsisV,
  faFileAlt,
  faToolbox,
  faBars,
  faEdit,
  faExpandArrowsAlt,
  faCopy,
  faTrashAlt,
  faPollH,
  faPencilAlt,
  faStar,
  faGripVertical,
  faArrowRight,
  faFile,
  faLocationArrow,
  faAt,
  faPhone,
  faSearch,
  faUserEdit,
  faList,
  faStore,
  faInfoCircle,
  faFileImport,
  faCross,
  faArrowLeft,
  faUserFriends,
  faDownload,
  faFileUpload,
  faExpand,
  faPlus,
  faMinus,
  faEllipsisH,
  faImage,
  faChevronDown,
  faChevronUp,
  faMinusCircle,
  faExclamationTriangle,
  faPlusCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEnvelope);
library.add(faUnlockAlt);
library.add(faEye);
library.add(faEyeSlash);
library.add(faCheck);
library.add(faTimes);
library.add(faChevronCircleDown);
library.add(faCheckCircle);
library.add(faCloudUploadAlt);
library.add(faUsers);
library.add(faFolderOpen);
library.add(faFolderPlus);
library.add(faFolder);
library.add(faIdCardAlt);
library.add(faUsersCog);
library.add(faCog);
library.add(faLifeRing);
library.add(faSignOutAlt);
library.add(faUserCircle);
library.add(faExclamationCircle);
library.add(faEllipsisV);
library.add(faFileAlt);
library.add(faToolbox);
library.add(faBars);
library.add(faEdit);
library.add(faExpandArrowsAlt);
library.add(faCopy);
library.add(faTrashAlt);
library.add(faPollH);
library.add(faPencilAlt);
library.add(faStar);
library.add(faGripVertical);
library.add(faArrowRight);
library.add(faFile);
library.add(faLocationArrow);
library.add(faAt);
library.add(faPhone);
library.add(faSearch);
library.add(faUserEdit);
library.add(faUserSlash);
library.add(faList);
library.add(faStore);
library.add(faInfoCircle);
library.add(faFileImport);
library.add(faCross);
library.add(faArrowLeft);
library.add(faUserFriends);
library.add(faDownload);
library.add(faFileUpload);
library.add(faExpand);
library.add(faPlus);
library.add(faMinus);
library.add(faEllipsisH);
library.add(faImage);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faMinusCircle);
library.add(faExclamationTriangle);
library.add(faPlusCircle);
library.add(faUser);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
export const bus = new Vue();

Vue.use(Antd);
Vue.use(VueClipboard);
const eventsHub = new Vue();

Vue.use(IdleVue, { idleTime: 24 * 60 * 60 * 1000, eventEmitter: eventsHub });

import "ant-design-vue/dist/antd.css";
import "./assets/base.scss";
import "./assets/redesign.scss";
import "./assets/custom-skin/custom-skin.scss";
import "./assets/dark-theme.scss";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
